import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  userName?: string;
  userId?: string;
  userToken?: string;
}

const initialState: AuthState = {
  userName: undefined,
  userId: undefined,
  userToken: undefined,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<AuthState>) {
      state.userName = action.payload.userName;
      state.userId = action.payload.userId;
      state.userToken = action.payload.userToken;
    },
    clearUser(state) {
      state.userName = undefined;
      state.userId = undefined;
      state.userToken = undefined;
    }
  },
});

export const { setUser, clearUser } = userSlice.actions;

export const selectUserName = (state: AuthState) => state.userName;
export const selectUserId = (state: AuthState) => state.userId;
export const selectUserToken = (state: AuthState) => state.userToken;
export const selectIsLoggedIn = (state: AuthState) => !!state.userToken;

export default userSlice.reducer;