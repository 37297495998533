import React, { useCallback, useState } from 'react';
import { Card, CardContent, Paper, Button, Typography, CardActions, DialogTitle } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectIsLoggedIn } from '../store/userSlice';
import { pageStyle } from './style';
import { useApi } from '../api/useApi';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

export default function UploadVideo() {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { uploadRequest } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUploadClick = useCallback(async () => {
    if (!selectedFile) return;

    try {
      const result = await uploadRequest(selectedFile);

      if (result.videoName) {
        enqueueSnackbar(`Video ${result.videoName} uploaded successfully`, { variant: 'success' });
        setSelectedFile(null);
        queryClient.invalidateQueries({
          queryKey: ['videos'],
        });
        navigate('/videos');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to upload video', { variant: 'error' });
    }
  }, [selectedFile, uploadRequest, enqueueSnackbar, queryClient, navigate]);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  if (!isLoggedIn) {
    return (
      <Paper style={pageStyle} className='flex justify-center items-center'>
        <Typography variant='h3' color='error'>Login required</Typography>
      </Paper>
    );
  }

  return (
    <Paper style={pageStyle} className='flex justify-center items-center'>
      <Card className='w-[500px] max-w-[80vw]'>
        <DialogTitle>Upload Video</DialogTitle>
        {selectedFile && <CardContent>{<Typography>Selected file: {selectedFile.name}</Typography>}</CardContent>}
        <CardActions>
          <input
            accept='video/*'
            style={{ display: 'none' }}
            id='upload-video'
            type='file'
            onChange={handleFileChange}
          />
          <label htmlFor='upload-video'>
            <Button variant='contained' color='primary' component='span'>
              Select Video
            </Button>
          </label>
          <Button variant='contained' color='secondary' onClick={handleUploadClick} disabled={!selectedFile}>
            Upload Video
          </Button>
        </CardActions>
      </Card>
    </Paper>
  );
}