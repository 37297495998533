import { useCallback, useEffect, useMemo } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import { useDispatch, useSelector } from "react-redux";
import { clearUser, selectIsLoggedIn, setUser } from "../store/userSlice";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const bc = new BroadcastChannel("test_channel");
    bc.onmessage = (payload) => {
      dispatch(setUser({ userToken: payload.data }));

      navigate("/upload");
    };
  }, [dispatch, navigate]);

  const broadcast = useMemo(() => {
    return new BroadcastChannel("test_channel");
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const code = params.get("id_token");

    if (!code) return;

    broadcast.postMessage(code);
    window.close();
  }, [broadcast]);

  const googleIcon = useMemo(() => {
    return <GoogleIcon />;
  }, []);

  const handleLogin = useCallback(() => {
    window.open(
      "https://n11434694-assignment2.auth.ap-southeast-2.amazoncognito.com/oauth2/authorize?client_id=7ltbc0oolr8q71f99kt4msn64k&response_type=token&scope=email+openid+phone&redirect_uri=https%3A%2F%2Fn11434694.cab432.com",
      "_blank"
    );
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(clearUser());

    navigate("/");
  }, [dispatch, navigate]);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle color="primary">
        {isLoggedIn ? "Logout" : "Login"}
      </DialogTitle>
      <DialogContent className="flex w-full items-center justify-center">
        {isLoggedIn ? (
          <Button fullWidth onClick={handleLogout} variant="outlined">
            Logout
          </Button>
        ) : (
          <Button
            fullWidth
            onClick={handleLogin}
            startIcon={googleIcon}
            variant="outlined"
          >
            Sign in
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
}
