import { useSelector } from "react-redux";
import { selectUserToken } from "../store/userSlice";
import { useCallback, useMemo } from "react";

export const BASE_URL = 'https://api.n11434694.cab432.com';

export function useApi() {
  const userToken = useSelector(selectUserToken);

  const loginRequest = useCallback(async () => {
    const result = await fetch(`${BASE_URL}/login`);

    return result.json();
  }, []);

  const uploadRequest = useCallback(async (file: File) => {
    if (!userToken) {
      throw new Error('User not logged in');
    }

    const formData = new FormData();
    formData.append('video', file);

    const result = await fetch(`${BASE_URL}/service-transcode/transcode`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: formData,
    });

    return result.json();
  }, [userToken]);

  const getVideosRequest = useCallback(async () => {
    if (!userToken) {
      throw new Error('User not logged in');
    }

    const result = await fetch(`${BASE_URL}/service-transcode/submissions`, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });

    return result.json();
  }, [userToken]);

  const shareVideoRequest = useCallback(async (videoName: string, submissionID: string, username: string) => {
    if (!userToken) {
      throw new Error('User not logged in');
    }

    const result = await fetch(`${BASE_URL}/service-share/share`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      body: JSON.stringify({
        videoName,
        submissionID,
        username
      })
    });

    if (!result.ok) {
      throw new Error('Network response was not ok');
    }

    return result.json();
  }, [userToken]);

  return useMemo(() => ({
    loginRequest,
    uploadRequest,
    getVideosRequest,
    shareVideoRequest,
  }), [loginRequest, uploadRequest, getVideosRequest, shareVideoRequest]);
}