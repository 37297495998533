import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearUser, selectIsLoggedIn } from '../store/userSlice';
import { useCallback } from 'react';

export default function Navbar() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();

  const handleAuthClick = useCallback(() => {
    if (!isLoggedIn) return;

    dispatch(clearUser());
  }, [dispatch, isLoggedIn]);

  return (
    <AppBar position="static" className='flex justify-center h-16'>
      <Toolbar>
        <Typography variant="h6" className='flex-1'>
          Video App
        </Typography>
        <Button color="inherit" component={Link} to="/upload">Upload</Button>
        <Button color="inherit" component={Link} to="/videos">Videos</Button>
        <Button color="inherit" onClick={handleAuthClick} component={Link} to="/">{isLoggedIn ? "Logout" : "Login"}</Button>
      </Toolbar>
    </AppBar>
  );
}