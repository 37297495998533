import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Paper, TextField, Typography } from "@mui/material";
import { pageStyle } from "./style";
import FileExplorer, { ExtendedTreeItemProps } from "../components/FileExplorer";
import { TreeViewBaseItem } from "@mui/x-tree-view";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../store/userSlice";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../api/useApi";
import { enqueueSnackbar } from "notistack";

const SEPARATOR = '$%^$^%';

export default function Videos() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { getVideosRequest, shareVideoRequest } = useApi();
  const [selectedVideo, setSelectedVideo] = useState<TreeViewBaseItem<ExtendedTreeItemProps> | undefined>();

  const { isPending, data } = useQuery({
    queryKey: ['videos'],
    queryFn: async () => {
      const response = await getVideosRequest();
      return [...response.submissions, ...response.sharedVideos];
    }
  });

  const items: TreeViewBaseItem<ExtendedTreeItemProps>[]  = useMemo(() => {
    if (!data) return [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return data.map((item: any, index: number) => {
      return {
        id: `${index}`,
        label: item.videoName,
        children: [
          { id: `${index}${SEPARATOR}360`, label: '360', fileType: 'video', url: item['360p'], submissionID: item.submissionID, videoName: item.videoName },
          { id: `${index}${SEPARATOR}480`, label: '480', fileType: 'video', url: item['480p'], submissionID: item.submissionID, videoName: item.videoName },
          { id: `${index}${SEPARATOR}720`, label: '720', fileType: 'video', url: item['720p'], submissionID: item.submissionID, videoName: item.videoName },
        ],
      };
    });
  }, [data]);

  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const openShareDialog = useCallback(() => {
    setShareDialogOpen(true);
  }, []);

  const [username, setUsername] = useState('');
  const handleShare = useCallback(async () => {
    if (!selectedVideo) {
      enqueueSnackbar('No video selected', { variant: 'error' });
      return;
    }

    const { videoName, submissionID } = selectedVideo;
    if (!videoName || !submissionID || !username) {
      enqueueSnackbar('Invalid video', { variant: 'error' });
      return;
    }

    try {
      await shareVideoRequest(videoName, submissionID, username);

      setShareDialogOpen(false);
      enqueueSnackbar(`Video ${videoName} shared with ${username}`, { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Failed to share video', { variant: 'error' });
    }
  }, [selectedVideo, shareVideoRequest, username]);

  if (isPending) {
    return (
      <Paper style={pageStyle} className='flex justify-center items-center'>
        <CircularProgress />
      </Paper>
    );
  }

  if (!isLoggedIn) {
    return (
      <Paper style={pageStyle} className='flex justify-center items-center'>
        <Typography variant='h3' color='error'>Login required</Typography>
      </Paper>
    );
  }

  return (
    <Paper style={pageStyle} className='flex flex-col md:flex-row gap-4 p-2'>
      <Dialog open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
        <DialogTitle>Share Video</DialogTitle>
        <DialogContent>
          <TextField placeholder="Share the video with a user" value={username} onChange={(e) => setUsername(e.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShareDialogOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleShare}>Share</Button>
        </DialogActions>
      </Dialog>
      <FileExplorer onSelectedItemsChange={(_event, id) => {
        if (!id) return;
        // find item in videos
        const parentId = id.split(SEPARATOR)[0];
        const childId = id;

        if (!childId.includes(SEPARATOR)) return;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const item = items.find((video: any) => video.id === parentId);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const childItem = item?.children?.find((child: any) => child.id === childId);

        setSelectedVideo(childItem);
      }} items={items}/>
      <div className="flex-1">
      {!!selectedVideo?.url && (
          <div>
            <div className="w-full flex flex-row justify-between">
              <Typography variant='h5'>{`Preview`}</Typography>
              <Button variant="contained" onClick={openShareDialog}>{"Share"}</Button>
            </div>
            <video controls className="w-full">
              <source src={selectedVideo.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </Paper>
  );
}