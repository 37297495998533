import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import UploadVideo from './pages/UploadVideo';
import Videos from './pages/Videos';
import Navbar from './components/NavBar';
import { Provider } from 'react-redux';
import store from './store/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';

const queryClient = new QueryClient();

export default function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
        <Router>
          <Navbar />
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/upload' element={<UploadVideo />} />
            <Route path='/videos' element={<Videos />} />
          </Routes>
        </Router>
        </SnackbarProvider>
      </QueryClientProvider>
    </Provider>
  );
}
